import React, { useState } from 'react';
// @ts-ignore
import { useTranslation } from 'react-i18next';
import useTermsBody from './data/useTermsBody';
import TermsAndConditionModal from './modal';

const pStyle = {
    linkText : {
        fontSize: "14px",
        fontStyle: "italic",
        opacity: 1,
    },
    spanText: {
        textDecoration: "underline",
        color: "#3b82f6",
        cursor: "pointer",
    }
};


type TermsAndConditionProps = {
  companyId: number,
  companyName: string,
}

const TermsAndCondition = ({companyId, companyName} : TermsAndConditionProps) => {
    const {t} = useTranslation();

    const [isUnitTermsOpen, setIsUnitTermsOpen] = useState(false);
    const [isUserTermsOpen, setIsUsersTermsOpen] = useState(false);
    const [isDataProcessingOpen, setIsDataProcessingOpen] = useState(false);

    const {data : unitTermsData, isError: unitTermsIsError, isLoading : unitTermsIsLoading} = useTermsBody(companyId, false, false)
    const {data : userTermsData, isError: userTermsIsError, isLoading: userTermsIsLoading} = useTermsBody(companyId, true, false)
    const {data : dataProcessingData, isError: dataProcessingError, isLoading : dataProcessingIsLoading} = useTermsBody(companyId, true, true)


    const isLoading = unitTermsIsLoading || userTermsIsLoading || dataProcessingIsLoading;
    const areAllErrors = unitTermsIsError && userTermsIsError && dataProcessingError;

    return (
        <>
        {
            isLoading || areAllErrors || (companyId === -1) ? (
                <> </>
            ):
            (
                <>
                <p style={pStyle.linkText}>
                    {t('Information about terms and condition for')}
                    {
                        // This might be possible to do a bit better with some design patterns but this is an old app, i guess😁😁
                    }
                    {
                        !unitTermsIsError && <span style={pStyle.spanText} onClick={() => setIsUnitTermsOpen(true)}> {companyName}</span>

                    }
                    {
                        ! userTermsIsError && !unitTermsIsError && <span> {t('and')} </span>
                    }
                    {
                        ! userTermsIsError && <span style={pStyle.spanText} onClick={() => setIsUsersTermsOpen(true)}> {t('Use of the plaform for users')}</span>
                    }
                    {
                        ! dataProcessingError && !userTermsIsError && !unitTermsIsError && <span> {t('and')} </span>
                    }
                    {
                        ! dataProcessingError && <span style={pStyle.spanText} onClick={() => setIsDataProcessingOpen(true)}> {t('Processing of personal data')}</span>
                    }
                </p>

                <TermsAndConditionModal
                     title={t('Terms and conditions')}
                     isOpen ={isUnitTermsOpen}
                     onOpenChange={setIsUnitTermsOpen}
                     data={unitTermsData} />
               <TermsAndConditionModal
                     title={t('Use of the plaform for users')}
                     isOpen ={isUserTermsOpen}
                     onOpenChange={setIsUsersTermsOpen}
                     data={userTermsData} />
               <TermsAndConditionModal
                     title={t('Processing of personal data')}
                     isOpen ={isDataProcessingOpen}
                     onOpenChange={setIsDataProcessingOpen}
                     data={dataProcessingData} />                    
                </>
            )
        }
        </>

    );   
};



export default (TermsAndCondition);
