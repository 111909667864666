import axios from 'axios';
import { node_terms_and_conditions_endpoint } from '../../../assets/jss/incept-sustainability-variables.jsx';
import { useCallback, useEffect, useState } from 'react';

const useTermsBody = (companyId: number, isForUser = false, isDataProcessing= false) => {
    const language = localStorage.getItem('preferredLanguage') || 'en';

    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState<string>('');
    const [isError, setIsError] = useState(false);

    const fetchTerms = useCallback(async () => {
        setIsLoading(true);
        let FETCH_URL = node_terms_and_conditions_endpoint(companyId, language, isForUser);
        FETCH_URL = isForUser 
                    ? `${FETCH_URL}&is_data_processing=${isDataProcessing}`
                    : FETCH_URL;

        try {
            const response = await axios.get(FETCH_URL);
            setData(response.data.data);
            setIsError(false);
        } catch (error) {
            setIsError(true);
        } finally {
            setIsLoading(false);
        }
    }, [companyId, language]);

    useEffect(() => {
        fetchTerms();
    }, [fetchTerms]);

    return { data, isLoading, isError };
};

export default useTermsBody;
