import React, { useState, useEffect, useCallback } from 'react'
import jwt from "jwt-decode";
import NewAppIFrame from 'components/NewAppIFrameViewer';
import clientConfig from 'client-config.js';
import i18next from 'i18next';



const ConsultRamboll = () => {

  const token = localStorage.getItem('token');
  const jwtToken = token ? jwt(token) : null;

  // Ensure token is valid
  if (!jwtToken) {
    return null;
  }
  
  return (
    <NewAppIFrame
      iFrameOrigin={clientConfig.nextUrl} // Production Mode ----
      //iFrameOrigin={'http://localhost:3010/'} // Development Mode ----
      iFramePagePath={`consult-ramboll`}
      title="Consult Ramboll"
      authToken={token}
      userId={jwtToken }
    />
  )
    
}

export default ConsultRamboll