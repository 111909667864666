import React, { CSSProperties } from 'react';


// @ts-ignore
import Modal from '../../Modal/Modal.jsx';
import ActionButton from '../../CustomButtons/ActionButton.jsx';
import { useTranslation } from 'react-i18next';

const pStyle: {
    modalTittle: CSSProperties;
    modalBody: CSSProperties;
} = {
    modalTittle: {
        alignSelf: "flex-start",
    },
    modalBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        overflow: "auto",
        maxHeight: "60dvh",
    },
};

interface TermsAndConditionModalProps {
    isOpen: boolean;
    onOpenChange: (value: boolean) => void;
    data: string;
    title: string;
}


const TermsAndConditionModal = ({
    title,
    isOpen,
    onOpenChange,
    data
}: TermsAndConditionModalProps) => {
    const {t} = useTranslation();

    return(
         <Modal
            show={isOpen}
            onHide={() => onOpenChange(false)}
            backdrop={true}>
            <div style={{ maxHeight: "70dvh",  }}>
                <h1 style={pStyle.modalTittle}>{title}</h1>
                <div
                    style={pStyle.modalBody}
                >
                  {data && 
                      <div dangerouslySetInnerHTML={{__html: data}}/>
                  } 
                  <ActionButton
                    size="auto"
                    onClick={() => {
                        onOpenChange(false);
                    }}
                    >
                        {t("Close")}
                    </ActionButton>
                </div>
            </div>
      </Modal>)

}

export default TermsAndConditionModal;