import React, { useRef, useEffect, useMemo, useState } from 'react';
import { useIframeMessaging } from './hooks/useIframeMessaging';
import i18next from 'i18next';

const NewAppIFrame = ({ iFrameOrigin, iFramePagePath, title, authToken, userId, }) => {

    const [language, setLanguage] = useState(() => localStorage.getItem('preferredLanguage') || 'en');

    const iframeRef = useRef(null);
    const [isMounted, setIsMounted] = useState(false);

    const initialMessages = useMemo(() => [
        { type: 'userId', value: userId },
        { type: 'auth', value: authToken },
    ], [authToken, language, userId]);

    const { handleLoad } = useIframeMessaging(iframeRef, iFrameOrigin, initialMessages, iFramePagePath, language);

    
    useEffect(() => {
        setIsMounted(true);
        return () => {
            setIsMounted(false);
        }
    }, []);

    useEffect (() =>{
        i18next.on('languageChanged', (lng) => {
            setLanguage(lng);
        });
    }, [])
    
    if (!isMounted) {
        return null; // or a loading indicator
    }
    
    return (
        <iframe
            ref={iframeRef}
            src={`${iFrameOrigin}${language}/${iFramePagePath}`}
            style={{ width: '100%', height: '94dvh', border: 'none', overflow: 'hidden'}}
            onLoad={handleLoad}
            title={title}
        />
    );
};

export default NewAppIFrame;