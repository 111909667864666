import { useCallback, useEffect, useRef, useState } from 'react';

export const useIframeMessaging = (iframeRef, iFrameOrigin, initialMessages, iFramePagePath, language) => {
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const messageQueue = useRef([]);

  const sendMessage = useCallback((messageType, messageValue) => {
    const message = {
      type: messageType,
      value: messageValue
    };

    if (isIframeLoaded && iframeRef.current && iframeRef.current.contentWindow) {
      iframeRef.current.contentWindow.postMessage(message, iFrameOrigin);
    } else {
      messageQueue.current.push(message);
      console.log("iframe not ready, message queued");
    }
  }, [iFrameOrigin, iframeRef, isIframeLoaded]);

  const handleLoad = useCallback(() => {
    setIsIframeLoaded(true);
  }, []);

  useEffect(() => {
    if (isIframeLoaded) {
      setTimeout(() => {
        initialMessages.forEach(message => sendMessage(message.type, message.value));
        while (messageQueue.current.length > 0) {
          const message = messageQueue.current.shift();
          sendMessage(message.type, message.value);
        }
        console.log('All queued messages sent');
      }, 100);
     
    }
    return () => {
      setIsIframeLoaded(false );
    }
  }, [isIframeLoaded, initialMessages, sendMessage]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.origin !== iFrameOrigin) return;

      if (event.data.type === 'navigate') {
        window.location.href = event.data.path;
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
      setIsIframeLoaded(false);
    };
  }, [iFrameOrigin, iFramePagePath, language]);

  return { sendMessage, handleLoad };
};